type AppInfoDto = (data: GetAppInfoResponse) => AppInfoDTOData

const appInfoDTO: AppInfoDto = data => ({
  accountId: data?.account_id,
  isWhiteLabel: data?.is_white_label,
  name: data?.name,
  description: data?.description,
  logo: data?.logo,
  headerLogo: data?.header_logo,
  headerTheme: data?.header_theme,
  logoWhiteLabel: data?.logo_white_label,
  favicon: data?.favicon_url,
  ogImage: data?.image_og,
  colors: {
    header: data?.header_color,
    primary: data?.theme.primary_color,
    secondary: data?.theme.secondary_color,
    dark: data?.theme.dark_color
  },
  domain: data?.domain,
  callbackLogoutUrl: data?.callback_logout_url,
  faqUrl: data?.faq_url,
  hasRecharge: data?.has_recharge,
  timezone: data?.timezone,
  currencyCode: data?.currency_code,
  rechargeMinValue: data?.recharge_min_value
})

export default appInfoDTO
