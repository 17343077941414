import styled, {
  DefaultTheme,
  css,
  FlattenSimpleInterpolation
} from 'styled-components'

const templatesModifiers: TemplatesModifiers<
  DefaultTheme,
  FlattenSimpleInterpolation
> = {
  warning: theme => css`
    background-color: ${theme.colors.warningLighten50};
    color: ${theme.colors.warning};
    border: none;
  `,
  primary: theme => css`
    background: ${theme.colors.primaryLighten60};
    color: ${theme.colors.primary};
    border: none;
  `,
  primaryDark: theme => css`
    color: ${theme.colors.primaryLighten60};
    background: ${theme.colors.primary};
    border: none;
  `,
  success: theme => css`
    background-color: ${theme.colors.successLighten40};
    color: ${theme.colors.successDark};
    border: none;
  `,
  successSolid: theme => css`
    color: ${theme.colors.successLighten45};
    background: ${theme.colors.success};
    border: none;
  `,
  danger: theme => css`
    background-color: ${theme.colors.dangerLighten40};
    color: ${theme.colors.danger};
    border: none;
  `,
  info: theme => css`
    background-color: ${theme.colors.infoLighten50};
    color: ${theme.colors.info};
    border: none;
  `
}

export const Badge = styled.span<PropsBadge>`
  background: transparent;
  border: 0;
  outline: 0;

  ${({ theme, template }) => css`
    --padding: 0.125rem 0.25rem;
    --borderRadius: 0.25rem;
    --fontSize: 0.75rem;
    --fontWeight: ${theme.font.semibold};
    --iconFontSize: 0.875rem;

    &.Badge_UI_Component_small {
      --padding: 0.125rem 0.25rem;
      --borderRadius: 0.125rem;
      --fontSize: 0.5rem;
      --fontWeight: ${theme.font.bold};
      --iconFontSize: 0.75rem;

      line-height: 1.25;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    margin: 0.125rem 0.25rem 0.125rem 0;
    padding: var(--padding);

    border-radius: var(--borderRadius);
    border: 1px solid ${theme.light};

    background: ${theme.inative};

    color: ${theme.dark};
    font-size: var(--fontSize);
    font-weight: var(--fontWeight);

    white-space: nowrap;
    text-overflow: ellipsis;

    .icon__left {
      display: inline-flex;
      margin-right: 0.25rem;
      font-size: var(--iconFontSize);
    }

    ${templatesModifiers[template]?.(theme)}
  `}
`
