import { t } from 'i18next'

export type FieldNamesByEntityProps = {
  fieldName: ResponseGetAuditLogFieldNames
  entity: ResponseGetAuditLogEntities
  action: ResponseGetAuditLogActions
}

type FieldNamesByEntityTerms = {
  [key in ResponseGetAuditLogEntities]: {
    [key in ResponseGetAuditLogActions]?: {
      [key in ResponseGetAuditLogFieldNames]?: string
    }
  }
}

export const labelsByEntities: FieldNamesByEntityTerms = {
  campaign: {
    create: {
      payload_create: t('rm:campaign.logs.create')
    },
    update: {
      active: t('rm:campaign.logs.update.active'),
      ads: t('rm:campaign.logs.update.ads'),
      name: t('rm:campaign.logs.update.name'),
      start_at: t('rm:campaign.logs.update.start_at'),
      end_at: t('rm:campaign.logs.update.end_at'),
      description: t('rm:campaign.logs.update.description'),
      'settings.daily_budget': t('rm:campaign.logs.update.settings.daily_budget'),
      'settings.cpm': t('rm:campaign.logs.update.settings.cpm'),
      'settings.cpc': t('rm:campaign.logs.update.settings.cpc'),
      'settings.products': t('rm:campaign.logs.update.settings.products')
    }
  },
  ad: {
    create: {
      payload_create: t('rm:ad.logs.create'),
      'settings.products': t('rm:ad.logs.create')
    },
    update: {
      active: t('rm:ad.logs.update.active'),
      'settings.cpm': t('rm:ad.logs.update.settings.cpm'),
      'settings.cpc': t('rm:ad.logs.update.settings.cpc')
    }
  },
  campaign_keyword: {
    create: {
      payload_create: t('rm:campaign.logs.create.keyword')
    },
    update: {
      active: t('rm:campaign.logs.update.keyword.active')
    }
  },
  campaign_category: {
    create: {
      payload_create: t('rm:campaign.logs.create.category')
    },
    update: {
      active: t('rm:campaign.logs.update.category.active')
    }
  }
}

export const fieldNamesByEntity = ({
  fieldName,
  entity,
  action
}: FieldNamesByEntityProps) => {
  return labelsByEntities?.[entity]?.[action]?.[fieldName] || fieldName
}
