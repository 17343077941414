import type {
  ChartTypeRegistry,
  ScriptableContext,
  TooltipItem
} from 'chart.js'
import { lighten, transparentize } from 'polished'

import { formatMoney } from '~/helpers'

//
export const maxValue = ({ data }) => {
  const {
    viewNumber = [],
    impressionsNumber = [],
    clicksNumber = [],
    conversionNumber = [],
    totalSpentNumber = [],
    incomeNumber = []
  } = data || {}

  const allValues = [
    ...clicksNumber,
    ...conversionNumber,
    ...viewNumber,
    ...impressionsNumber
  ]
  const allMoneyValues = [...totalSpentNumber, ...incomeNumber]

  return {
    metrics: Math.max(...allValues),
    clicksAndConversions: Math.max(...[...clicksNumber, ...conversionNumber]),
    conversions: Math.max(...conversionNumber),
    money: Math.max(...allMoneyValues)
  }
}

//
export const abbreviateCurrency = (value: number) => {
  if (value >= 1000000) {
    return `R$ ${Math.floor(value / 1000000)}M`
  } else if (value >= 1000) {
    return `R$ ${Math.floor(value / 1000)}K`
  } else {
    return formatMoney(value)
  }
}

//

type FormatChartStyleProps = {
  color: string
  bgStyle?: 'solid' | 'alphaGradient'
}

const getBgColor = ({ bgStyle, color }: FormatChartStyleProps) => {
  const formatter = {
    solid: color,
    alphaGradient: (context: ScriptableContext<'line'>) => {
      const ctx = context.chart.ctx
      const gradient = ctx.createLinearGradient(0, 0, 0, 280)
      gradient.addColorStop(0, transparentize(0.65, color))
      gradient.addColorStop(1, transparentize(0.85, color))
      return gradient
    }
  }

  return formatter?.[bgStyle] || color
}

export const formatStylesChart = ({
  color,
  bgStyle = 'solid'
}: FormatChartStyleProps) => ({
  backgroundColor: getBgColor({ color, bgStyle }),
  borderColor: color,
  borderWidth: 2,
  pointBorderColor: color,
  pointHoverBorderColor: color,
  pointBorderWidth: 2,
  pointBackgroundColor: lighten(0.3, color),
  pointHoverBackgroundColor: lighten(0.2, color),
  cubicInterpolationMode: 'monotone',
  pointStyle: 'rectRounded',
  pointRadius: 6,
  pointHoverRadius: 8,

  borderRadius: 8,
  maxBarThickness: 72
})

/**
 *
 */
type TooltipItemContext = TooltipItem<keyof ChartTypeRegistry>
export const handleLabelCallbackTooltip = (context: TooltipItemContext) => {
  let label = context.dataset.label || ''

  const id = context.dataset.yAxisID
  const moneyAxis = ['y-income', 'y-totalSpent']
  const isMoney = moneyAxis.includes(id)

  if (label) {
    label += ': '
  }

  if (isMoney && context.parsed.y !== null) {
    label += new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(context.parsed.y)
  } else {
    label += context?.formattedValue || context.parsed.y
  }

  return label
}

/**
 *
 */
export const handleItemSortTooltip = (
  a: TooltipItemContext,
  b: TooltipItemContext
) => {
  const order = {
    a: a.dataset.order,
    b: b.dataset.order
  }

  if (order.a < order.b) {
    return -1
  }

  if (order.a > order.b) {
    return 1
  }

  return 0
}
