import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'

import { t } from 'i18next'
import { isEqual } from 'lodash'

import AlertInfo from '~/components/AlertInfo'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { formatDateToString, formatSearchString } from '~/helpers'
import handleQueryParams from '~/helpers/handleQueryParams'
import { handleSortDirection } from '~/helpers/sortData'
import { useAlert } from '~/hooks/useAlert'
import useFetchSWR from '~/hooks/useFetchSWR'
import ExportButton from '~/modules/retailMedia/components/ExportButton'
import { formatGroceryListAdvertisers } from '~/modules/retailMedia/dtos/grocery/advertiser/list'
import { queryFilterListAdvertisers } from '~/modules/retailMedia/pages/links/filters'
import { reportAdvertisersBaseURL } from '~/modules/retailMedia/services/grocery/advertiser'
import { ADVERTISERS_LIST_QUERY } from '~/modules/retailMedia/store/advertiser/constants'
import { Footer } from '~/pages/products/list/styles'
import { useAppSelector } from '~/store/hooks'

import { schema } from './schema'

const dynamicKeys: string[] = [
  'start_date',
  'end_date',
  'advertiser_name',
  'quantity'
]

const AdvertisersTable = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [sortDirection, setSortDirection] = useState(null)
  const [sortKey, setSortKey] = useState(null)
  const [queryParams, setQueryParams] = useState({})
  const [innerLoading, setInnerLoading] = useState(false)
  const [response, setResponse] = useState<ResponseListGroceryAdvertisers>()

  const {
    filters: { query },
    datepickerHeader: { startDate, endDate }
  } = useAppSelector(state => state)

  const filteredQuery = useMemo(
    () => query?.[ADVERTISERS_LIST_QUERY] || null,
    [query]
  )

  const params = useMemo(
    () => ({
      page,
      start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
      end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
      advertiser_name: filteredQuery,
      account_info: true,
      quantity: perPage,
      order_direction: sortDirection,
      order_by: sortKey,
      count: true
    }),
    [startDate, endDate, perPage, page, filteredQuery, sortDirection, sortKey]
  )

  useLayoutEffect(() => {
    if (isEqual(params, queryParams)) {
      return
    }

    const { shouldResetFixedKeys } = handleQueryParams({
      params,
      currentParams: queryParams,
      dynamicKeys
    })

    if (shouldResetFixedKeys) {
      params.page = 1
      setPage(1)
    }
    setInnerLoading(true)
    setQueryParams(params)
  }, [params, queryParams])

  /**
   * Handle request
   */
  const requestURL = useMemo(
    () => `${reportAdvertisersBaseURL}?${formatSearchString(queryParams)}`,
    [queryParams]
  )

  const {
    data: dataSWR,
    error,
    isValidating
  } = useFetchSWR<ResponseListGroceryAdvertisers>({
    url: requestURL,
    refreshInterval: 60000,
    revalidateOnFocus: false
  })

  const [AlertOnError] = useAlert(error)

  useEffect(() => {
    if (dataSWR) {
      setResponse(dataSWR)
    }
  }, [dataSWR])

  const { data, currentPage, pages, total } = useMemo(() => {
    const formattedData = response?.data
      ? formatGroceryListAdvertisers(response.data)
      : null

    setInnerLoading(false)
    return {
      currentPage: response?.currentPage,
      pages: response?.pages,
      total: response?.total,
      data: formattedData
    }
  }, [response])

  /**
   * Handle Pagination
   */

  const handlePagination = useCallback((page: number) => {
    setPage(page)
  }, [])

  const handleItemsPerPageChange = useCallback(
    ({ value }: { value: number }) => {
      setPerPage(value)
    },
    []
  )
  /**
   * Sort list
   */

  const handleSortList = useCallback(
    (data: OnSortProps) => {
      const selectedKey = data.key
      const sortInitialDirection = data?.sortInitialDirection

      const response = handleSortDirection({
        selectedKey,
        currentSortDirection: sortDirection,
        currentSortKey: sortKey,
        sortInitialDirection
      })

      setSortDirection(response.sortDirection)
      setSortKey(response.sortKey)
    },
    [sortDirection, sortKey]
  )

  return (
    <>
      <HeaderPages
        title={t('rm:AdvertisersList')}
        sideRightComponent={
          <ExportButton
            queryParams={formatSearchString(queryParams)}
            exportType={'advertisers'}
          />
        }
      />

      <Filters {...queryFilterListAdvertisers} />

      <Loading status={!data && isValidating}>
        {t('common:actions.loadingData')}...
      </Loading>

      <LoadingPopUp isActive={isValidating} />

      <AlertOnError />

      <Panel noPaddingBody isLoading={!data}>
        <Loading status={innerLoading} onlyLine />

        {!!data && (
          <Table
            schema={schema}
            freeze="first-row-and-column"
            data={data}
            total={total}
            sideBorder
            smallerRowPadding
            // sort settings
            activeSortDirection={sortDirection}
            activeSortKey={sortKey}
            onSort={handleSortList}
          />
        )}

        {total === 0 && data && (
          <AlertInfo
            template="warning"
            text={t('common:table.emptyFiltered')}
          />
        )}
      </Panel>

      <Footer>
        <ListPagination
          total={total}
          label={total > 1 ? t('rm:Advertisers') : t('rm:Advertiser')}
          currentPage={page}
          itemsPerPage={perPage}
          menuPlacement="top"
          onClickPagination={handlePagination}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </Footer>
    </>
  )
}

export default AdvertisersTable
