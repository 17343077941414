import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Filters } from '~/components/Filters'
import { FilterSearch } from '~/components/FilterSearch'
import HeaderPages from '~/components/HeaderPages'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { useAlert } from '~/hooks/useAlert'

import { handleLocationPerPage, indexLocation } from '../actions'
import * as types from '../constants'
import { schema } from './table-schema'

import * as S from './styles'

const LocationsList = () => {
  const [page, setPage] = useState(1)

  const {
    listLocationsLoading,
    listLocationsPerPage,
    listLocations: { data, total },
    listLocationsPage,
    error
  } = useSelector(state => state.locations)

  const { query, states, locationType, locationStatus } = useSelector(
    state => state.filters
  )

  const [AlertOnError] = useAlert(error)

  const dispatch = useDispatch()

  const filteredQuery = useMemo(
    () => query?.[`${types.LOCATION_REDUX_QUERY}`],
    [query]
  )

  const externalId = useMemo(() => query?.external_id, [query])

  /**
   * Montando a queryString paga fazer a requisição.
   */
  const queryString = useMemo(
    () => ({
      page,
      quantity: listLocationsPerPage,
      query: filteredQuery,
      externalId,
      address_state: states?.value,
      active: locationStatus?.value,
      type: locationType?.value
    }),
    [
      page,
      externalId,
      listLocationsPerPage,
      filteredQuery,
      states,
      locationType,
      locationStatus
    ]
  )

  console.log(queryString)

  useEffect(() => {
    dispatch(indexLocation(queryString))
  }, [dispatch, queryString])

  /**
   * Gerenciando a paginação
   */
  const handlePagination = page => {
    setPage(page)
  }

  const handleItemsPerPageChange = ({ value }) => {
    dispatch(handleLocationPerPage(value))
  }

  const Pagination = () => {
    return (
      <>
        {!!data && total > 0 && (
          <>
            <ListPagination
              total={total}
              label={total > 1 ? 'locais' : 'local'}
              currentPage={listLocationsPage}
              itemsPerPage={listLocationsPerPage}
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </>
        )}
      </>
    )
  }

  return (
    <>
      <PageTitle title="Locais > Listagem" />

      <HeaderPages
        title="Locais"
        sideLeftComponent={
          <>
            <FilterSearch
              placeholder="Buscar Local (Nome exato)"
              searchOnEnter={false}
              keyState={`${types.LOCATION_REDUX_QUERY}`}
              live
            />
          </>
        }
      />

      <Filters
        query
        querySize="1x"
        liveQuery
        queryKeyState="external_id"
        queryPlaceholder="Buscar por ID do local"
        states
        locationType
        locationStatus
      />

      <Loading status={listLocationsLoading}>Carregando locais</Loading>

      <AlertOnError />

      <Pagination />

      <Panel noPaddingBody isLoading={listLocationsLoading}>
        <Table schema={schema} data={data} className="cardOnMobile" />

        <S.Footer>
          <Pagination />
        </S.Footer>
      </Panel>
    </>
  )
}

export default LocationsList
