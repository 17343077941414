import { useEffect, useRef, useState } from 'react'

import { intervalToDuration, isFuture } from 'date-fns'

import { Spinner } from '~/components/Spinner'

import { Label } from '../unform'

const Countdown = ({ timeToExpiry }: { timeToExpiry: string }) => {
  const [remainingTime, setRemainingTime] = useState<string | null>(null)
  const [expired, setExpired] = useState<boolean>(false)

  const refreshTimer = useRef<NodeJS.Timeout | null>(null)

  const zeroPad = (num = 0) => String(num).padStart(2, '0')

  useEffect(() => {
    refreshTimer.current = setInterval(async () => {
      const duration = intervalToDuration({
        start: new Date(timeToExpiry),
        end: new Date()
      })

      const formattedTime = `${zeroPad(duration.minutes)}:${zeroPad(
        duration.seconds
      )}`

      setRemainingTime(formattedTime)

      if (formattedTime === '00:00') {
        clearInterval(refreshTimer.current as NodeJS.Timeout)
        setExpired(true)
      }
    }, 1000)

    return () => {
      clearInterval(refreshTimer.current as NodeJS.Timeout)
    }
  }, [timeToExpiry])

  useEffect(() => {
    if (!isFuture(new Date(timeToExpiry)) || remainingTime === '00:00') {
      clearInterval(refreshTimer.current as NodeJS.Timeout)

      setRemainingTime('00:00')

      setExpired(true)
    }
  }, [remainingTime, timeToExpiry])

  return <Label>{expired ? 'Expirado' : remainingTime || <Spinner />}</Label>
}

export default Countdown
