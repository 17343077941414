import i18n from '~/i18n'
const { t } = i18n

const root = {
  label: 'Retail Media',
  dashboard: 'Dashboard',
  publishers: 'Publishers',
  subPublishers: t('rm:Network'),
  advertisers: t('rm:Advertisers'),
  campaigns: t('rm:Campaigns'),
  ads: t('rm:Ads'),
}

const placements = {
  menu: {
    root: t('rm:placements.list.menu'),
    create: t('rm:registerPlacement'),
  }
}

const campaign = {
  menu: {
    main: 'Dashboard',
    campaigns: t('rm:Campaigns'),
    ads: t('rm:Ads'),
    list: t('rm:listingCampaigns'),
    create: t('rm:createCampaign'),
    view: t('rm:viewCampaigns'),
    edit: t('rm:editCampaign'),
    metrics: t('rm:metricsCampaigns'),
    wallet: t('rm:Wallets')
  },
  ad_types: {
    product: t('rm:Products'),
    banner: t('rm:Banners'),
    video: t('rm:Videos'),
    sponsored_brand: t('rm:sponsoredBrand'),
    digital_signage: t('rm:digitalSignage')
  }
}

export { root, campaign, placements, campaign as retailMediacampaignTexts }
