import { t } from 'i18next'

import { DatepickerHeader } from '~/components/datepicker-header'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import DashboardRetailMediaCampaigns from '~/modules/retailMedia/components/DashboardRetailMediaCampaigns'
import MainTabs from '~/modules/retailMedia/components/MainTabs'

import SubPublisherTable from '../../components/_tables/SubPublisherTable'
import { queryFilterListPublishers } from './filters'

import * as S from './styles'

const SubPublishersDashboard = () => {
  return (
    <>
      <PageTitle title={`Retail Media - ${t('rm:NetworkDashBoard')}`} />

      <HeaderPages
        title={`Retail Media - ${t('rm:NetworkDashBoard')}`}
        className="has-datepicker"
        sideRightComponent={
          <S.WrapperHeader>
            <DatepickerHeader />
          </S.WrapperHeader>
        }
      />

      <MainTabs />

      <Filters {...queryFilterListPublishers} />

      <DashboardRetailMediaCampaigns origin="sub_publisher" />

      <SubPublisherTable />
    </>
  )
}

export default SubPublishersDashboard
