import React, { useCallback, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import Panel from '~/components/Panel'
import { Badge } from '~/components/UI/Badge'
import { Label } from '~/components/unform'
import { formatMoney, getDurationInDays } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import {
  listCampaignPlacesKey,
  listCampaignPlacesService
} from '~/modules/retailMedia/services/campaign-places'
import { getMinCostService } from '~/modules/retailMedia/services/campaign/min-cost'

import BalanceByPublisher from '../../../BalanceByPublisher'
import { requestMinCostValidator } from './validator'

/**
 * Format by type
 */
const fakeAsset = {
  video: {
    assets: [
      {
        type: 'video',
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      }
    ]
  }
}

const Investment = ({ formData, campaignId, initialData }: InvestimetProps) => {
  const { t } = useAPPTranslation()

  const campaignData = formData || initialData

  const QUERY_KEY = [listCampaignPlacesKey, campaignId, 1, 1]

  const { data: placesLength } = useQuery({
    enabled: !!campaignId,
    queryKey: QUERY_KEY,
    queryFn: async () => {
      const { data } = await listCampaignPlacesService({
        campaignId: campaignId as string,
        page: 1,
        quantity: 1,
        count: true
      })
      return data.total
    }
  })

  // format data to be used in the request
  const bodyRequestMinCost = useCallback(
    () =>
      ({
        ...campaignData,
        ...fakeAsset
      }) as DTOCreateDigitalSignageCampaignResponse,
    [campaignData]
  )

  // On create
  const dataIsValid = useMemo(() => {
    if (campaignId) return !isNaN(placesLength)

    try {
      requestMinCostValidator.validateSync(
        { ...campaignData, ...fakeAsset },
        { abortEarly: false }
      )
      return true
    } catch (error) {
      console.log('🚀 ~ dataIsValid ~ error:', { ...error })
      return false
    }
  }, [campaignData, campaignId, placesLength])

  const { data } = useQuery({
    enabled: dataIsValid,
    queryKey: [
      'minCosts',
      campaignData?.start_at,
      campaignData?.end_at,
      campaignData?.places?.length || placesLength,
      campaignData?.publisher_id
    ],
    queryFn: async () => {
      const response = await getMinCostService({
        body: bodyRequestMinCost()
      })

      return response.data
    }
  })

  const values = useMemo(() => {
    const startDate = campaignData?.start_at
    const endDate = campaignData?.end_at
    const hasDate = startDate && endDate
    const getDuration = () =>
      getDurationInDays({
        startDate,
        endDate,
        returnType: 'number'
      }) as number

    const duration = hasDate ? getDuration() : null

    const totalPlaces = campaignData?.settings?.places?.length || placesLength

    const dailyCost = isNaN(data?.daily_cost)
      ? null
      : formatMoney(data?.daily_cost)

    const totalDailyCost =
      isNaN(data?.daily_cost) || isNaN(totalPlaces)
        ? null
        : formatMoney(data.daily_cost * totalPlaces)

    const totalCost =
      isNaN(data?.daily_cost) || isNaN(duration) || isNaN(totalPlaces)
        ? null
        : formatMoney(data.daily_cost * duration * totalPlaces)

    return {
      duration,
      totalPlaces,
      dailyCost,
      totalDailyCost,
      totalCost
    }
  }, [
    campaignData?.start_at,
    campaignData?.end_at,
    campaignData?.settings?.places?.length,
    placesLength,
    data?.daily_cost
  ])

  const hasPending = useMemo(
    () => Object.values(values).some(value => !value),
    [values]
  )

  return (
    <Panel
      title={
        <div className="d-flex align-items-center gap-2">
          <span>{t('rm:campaign.create.Investment.title')}</span>
          <div className="fw-normal">
            <BalanceByPublisher
              id={campaignData?.advertiser_id}
              idAccountType="grocery"
            />
          </div>
        </div>
      }
      description={t('rm:campaign.create.Investment.description')}
    >
      {hasPending && (
        <header className="mb-3">
          <AlertInfo
            template="warning"
            text={t(
              'rm:campaign.create.Investment.info.pendingValuesWillFilled'
            )}
          />
        </header>
      )}

      <section className="d-flex gap-3">
        <div className="pe-3 border-end">
          <Label text={t('rm:campaign.create.Investment.duration.title')} />
          <span>
            {values.duration || (
              <Badge template="warning">
                {t('rm:campaign.create.Investment.duration.placeholder')}
              </Badge>
            )}
          </span>
        </div>

        <div className="span-start pe-3 border-end">
          <Label text={t('rm:campaign.create.Investment.places.title')} />
          <span>
            {values.totalPlaces || (
              <Badge template="warning">
                {t('rm:campaign.create.Investment.places.placeholder')}
              </Badge>
            )}
          </span>
        </div>

        <div className="pe-3 border-end">
          <Label text={t('rm:campaign.create.Investment.dailyCost.title')} />
          <span>
            {values.totalDailyCost || (
              <Badge template="warning">
                {t('rm:campaign.create.Investment.dailyCost.placeholder')}
              </Badge>
            )}
          </span>
        </div>

        <div>
          <Label text={t('rm:campaign.create.Investment.totalCost.title')} />
          <span>
            {values.totalCost || (
              <Badge template="warning">
                {t('rm:campaign.create.Investment.totalCost.placeholder')}
              </Badge>
            )}
          </span>
        </div>
      </section>

      <footer className="mt-3">
        <AlertInfo
          template="info"
          text={t('rm:campaign.create.Investment.info.dailyCost')}
        />
      </footer>
    </Panel>
  )
}

export default Investment
