import { shade, lighten, saturate, transparentize, darken } from 'polished'

export const primary = {
  title: 'primary' as string,
  border_radius: '0.25rem',

  // Old variables

  primary: '#3c3584' as string,
  secondary: '#ffb450',
  success: '#4bbf73',

  info: '#1f9bcf',
  warning: '#e08300',
  danger: '#d9534f',

  light: '#f8f9fa',
  dark: '#354052',

  hover: '#5b51c8',
  inative: '#f3f3f3',

  white: '#fff',
  gray: '#aeb1b5',
  tertiary: '#ddd',

  // New variables
  topBarHeight: '48px',
  bottomBarHeight: '54px',

  border: {
    radius: {
      small: '0.125rem',
      medium: '0.25rem',
      large: '.5rem',
      rounded: '50%'
    }
  },

  colors: {
    // Começo DS Novo
    neutral: {
      low: {
        lightest: `#ABACBA`,
        light: `#9D9BAA`,
        medium: `#5F5D6E`,
        dark: `#26252C`,
        pure: `#09090b`
      },
      high: {
        pure: `#ffffff`,
        lightest: `#F9F9FA`,
        light: `#F5F5F6`,
        medium: `#EBEBEE`,
        dark: `#C1C2CC`
      }
    },
    // Fim DS Novo
    header: `#3c3584` as string,
    primary: `#3c3584` as string,
    primaryLighten30: `${lighten(0.3, '#3c3584')}` as string,
    primaryLighten45: `${lighten(0.45, '#3c3584')}` as string,
    primaryLighten50: `${lighten(0.45, '#3c3584')}` as string,
    primaryLighten60: `${lighten(0.6, '#3c3584')}` as string,
    primaryLighten61: `${lighten(0.61, '#3c3584')}` as string,
    secondary: `#ffb450`,
    secondaryLighten10: `${lighten(0.1, '#ffb450')}`,
    secondaryLighten30: `${lighten(0.3, '#ffb450')}`,
    secondaryRGB: '255, 180, 80',

    light: `#f8f9fa`,
    dark: `#354052`,

    hover: `#5b51c8`,

    white: `#fff`,
    inative: `#f3f3f3`,
    outlineGray: `#EBEBEE`,
    tertiary: `#ddd`,
    neutralHigh: `#c1c2cc`,
    gray: `#aeb1b5`,
    neutralColor: `#ABADBB`,
    stormGray: `#66687c`,
    grayScale: `#5a5f66`,
    neutralColorDark: `#2f3141`,
    neutralLow: `#1d1f36`,

    success: `#4BBF73`,
    successAlert: `rgb(155, 191, 115)`,
    successDark: `#29630D`,
    successLighten20: `${lighten(0.2, '#4BBF73')}`,
    successLighten30: `${lighten(0.3, '#4BBF73')}`,
    successLighten40: `${lighten(0.4, '#4BBF73')}`,
    successLighten45: `${lighten(0.45, '#4BBF73')}`,
    successShade20: `${shade(0.2, '#4BBF73')}`,

    info: `#1f9bcf`,
    infoLighten20: `${lighten(0.2, '#1f9bcf')}`,
    infoLighten50: `${lighten(0.5, '#1f9bcf')}`,

    warning: `#e08300`,
    warningPure: `#fa9014`,
    warningPureMedium: `#d77505`,
    warningShade20: `${shade(0.2, '#e08300')}`,
    warningLighten40: `${lighten(0.4, '#e08300')}`,
    warningLighten50: `${lighten(0.5, '#e08300')}`,

    danger: `#d9534f`,
    dangerLighten30: `${lighten(0.3, '#d9534f')}`,
    dangerLighten40: `${lighten(0.4, '#d9534f')}`,
    dangerSaturate20: `${saturate(0.2, '#d9534f')}`
  },

  modal: {
    overlay: transparentize(0.25, darken(0.1, '#3c3584'))
  },

  font: {
    family:
      "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif",
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    bolder: 800,
    sizes: {
      tiny: '.5rem',
      xxsmall: '.625rem', // 10px
      xsmall: '.75rem',
      small: '.875rem',
      medium: '1rem', // 16px
      large: '1.125rem',
      xlarge: '1.25rem',
      xxlarge: '1.5rem', // 24px
      xxxlarge: '1.75rem'
    }
  },
  spacings: {
    xtiny: '0.125rem', // 2px
    tiny: '.25rem', // 4px
    xxxsmall: '.5rem', // 8px
    xxsmall: '.625rem', // 10px
    xsmall: '.75rem', // 12px
    small: '.875rem', // 14px
    medium: '1rem', // 16px
    large: '1.125rem', // 18px
    xlarge: '1.25rem', // 20px
    xxlarge: '1.5rem', // 24px
    xxxlarge: '1.75rem' // 28px
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
    space: 9999
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  },
  shadows: {
    dp0: '0px 0px 10px rgba(110, 113, 145, 0), 0px 0 0 rgba(110, 113, 145, 0)',
    dp2: '0px 2px 2px rgba(110, 113, 145, 0.08), 0px 2px 4px rgba(110, 113, 145, 0.08)',
    dp4: '0px 4px 4px rgba(110, 113, 145, 0.08), 0px 4px 8px rgba(110, 113, 145, 0.06)',
    dp8: '0px 8px 8px rgba(110, 113, 145, 0.08), 0px 8px 16px rgba(110, 113, 145, 0.06)',
    dp12: '0px 12px 12px rgba(110, 113, 145, 0.08), 0px 16px 24px rgba(110, 113, 145, 0.08)',
    dp16: '0px 16px 16px rgba(110, 113, 145, 0.08), 0px 24px 32px rgba(110, 113, 145, 0.08)',
    dp20: '0px 20px 20px rgba(110, 113, 145, 0.1), 0px 32px 40px rgba(110, 113, 145, 0.1)',
    dp24: '0px 24px 24px rgba(110, 113, 145, 0.1), 0px 40px 48px rgba(110, 113, 145, 0.25)',
    dp28: '0px 28px 28px rgba(110, 113, 145, 0.15), 0px 48px 56px rgba(110, 113, 145, 0.25)',
    negative: {
      dp2: '0px -2px 2px rgba(110, 113, 145, 0.04), 0px -2px 6px rgba(110, 113, 145, 0.04)',
      dp4: '0px -4px 4px rgba(110, 113, 145, 0.06), 0px -4px 8px rgba(110, 113, 145, 0.06)',
      dp12: '0px -12px 12px rgba(110, 113, 145, 0.08), 0px -16px 24px rgba(110, 113, 145, 0.08)'
    },
    fromEnd: {
      dp12: '-12px 0 12px rgba(110, 113, 145, 0.08), -16px 0 24px rgba(110, 113, 145, 0.08)',
      dp20: '-20px 0x 20px rgba(110, 113, 145, 0.1), -32px 0 40px rgba(110, 113, 145, 0.1)'
    }
  }
} as const

export const neutral = {
  title: 'neutral',
  border_radius: '0.25rem',

  // Old variables

  primary: '#354052',
  secondary: '#ffb450',
  success: '#4bbf73',

  info: '#1f9bcf',
  warning: '#e08300',
  danger: '#d9534f',

  light: '#f8f9fa',
  dark: '#354052',

  hover: '#5b51c8',
  inative: '#f3f3f3',

  white: '#fff',
  gray: '#aeb1b5',
  tertiary: '#ddd',

  // New variables
  topBarHeight: '48px',
  bottomBarHeight: '54px',

  border: {
    radius: {
      small: '0.125rem',
      medium: '0.25rem',
      large: '.5rem',
      rounded: '50%'
    }
  },

  colors: {
    // Começo DS Novo
    neutral: {
      low: {
        lightest: `#ABACBA`,
        light: `#9D9BAA`,
        medium: `#5F5D6E`,
        dark: `#26252C`,
        pure: `#09090b`
      },
      high: {
        pure: `#ffffff`,
        lightest: `#F9F9FA`,
        light: `#F5F5F6`,
        medium: `#EBEBEE`,
        dark: `#C1C2CC`
      }
    },
    // Fim DS Novo
    primary: '#354052',
    primaryLighten30: `${lighten(0.3, '#354052')}`,
    primaryLighten45: `${lighten(0.45, '#354052')}`,
    primaryLighten50: `${lighten(0.45, '#354052')}`,
    primaryLighten60: `${lighten(0.6, '#354052')}`,
    primaryLighten61: `${lighten(0.61, '#354052')}`,

    secondary: `#ffb450`,
    secondaryLighten10: `${lighten(0.1, '#ffb450')}`,
    secondaryLighten30: `${lighten(0.3, '#ffb450')}`,
    secondaryRGB: '255, 180, 80',

    light: `#f8f9fa`,
    dark: `#354052`,

    hover: `#5b51c8`,

    white: `#fff`,
    inative: `#f3f3f3`,
    outlineGray: `#EBEBEE`,
    tertiary: `#ddd`,
    neutralHigh: `#c1c2cc`,
    gray: `#aeb1b5`,
    neutralColor: `#ABADBB`,
    stormGray: `#66687c`,
    grayScale: `#5a5f66`,
    neutralColorDark: `#2f3141`,
    neutralLow: `#1d1f36`,

    success: `#4BBF73`,
    successAlert: `rgb(155, 191, 115)`,
    successDark: `#29630D`,
    successLighten20: `${lighten(0.2, '#4BBF73')}`,
    successLighten30: `${lighten(0.3, '#4BBF73')}`,
    successLighten40: `${lighten(0.4, '#4BBF73')}`,
    successLighten45: `${lighten(0.45, '#4BBF73')}`,
    successShade20: `${shade(0.2, '#4BBF73')}`,

    info: `#1f9bcf`,
    infoLighten20: `${lighten(0.2, '#1f9bcf')}`,
    infoLighten50: `${lighten(0.5, '#1f9bcf')}`,

    warning: `#e08300`,
    warningPure: `#fa9014`,
    warningPureMedium: `#d77505`,
    warningShade20: `${shade(0.2, '#e08300')}`,
    warningLighten40: `${lighten(0.4, '#e08300')}`,
    warningLighten50: `${lighten(0.5, '#e08300')}`,

    danger: `#d9534f`,
    dangerLighten30: `${lighten(0.3, '#d9534f')}`,
    dangerLighten40: `${lighten(0.4, '#d9534f')}`,
    dangerSaturate20: `${saturate(0.2, '#d9534f')}`
  },

  modal: {
    overlay: transparentize(0.25, darken(0.1, '#3c3584'))
  },

  font: {
    family:
      "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif",
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    bolder: 800,
    sizes: {
      xxsmall: '.625rem',
      xsmall: '.75rem',
      small: '.875rem',
      medium: '1rem', // 16px
      large: '1.125rem',
      xlarge: '1.25rem',
      xxlarge: '1.5rem', // 24px
      xxxlarge: '1.75rem'
    }
  },
  spacings: {
    xtiny: '0.125rem', // 2px
    tiny: '.25rem', // 4px
    xxxsmall: '.5rem', // 8px
    xxsmall: '.625rem', // 10px
    xsmall: '.75rem', // 12px
    small: '.875rem', // 14px
    medium: '1rem', // 16px
    large: '1.125rem', // 18px
    xlarge: '1.25rem', // 20px
    xxlarge: '1.5rem', // 24px
    xxxlarge: '1.75rem' // 28px
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  },
  shadows: {
    dp0: '0px 0px 10px rgba(110, 113, 145, 0), 0px 0 0 rgba(110, 113, 145, 0)',
    dp2: '0px 2px 2px rgba(110, 113, 145, 0.08), 0px 2px 4px rgba(110, 113, 145, 0.08)',
    dp4: '0px 4px 4px rgba(110, 113, 145, 0.08), 0px 4px 8px rgba(110, 113, 145, 0.06)',
    dp8: '0px 8px 8px rgba(110, 113, 145, 0.08), 0px 8px 16px rgba(110, 113, 145, 0.06)',
    dp12: '0px 12px 12px rgba(110, 113, 145, 0.08), 0px 16px 24px rgba(110, 113, 145, 0.08)',
    dp16: '0px 16px 16px rgba(110, 113, 145, 0.08), 0px 24px 32px rgba(110, 113, 145, 0.08)',
    dp20: '0px 20px 20px rgba(110, 113, 145, 0.1), 0px 32px 40px rgba(110, 113, 145, 0.1)',
    dp24: '0px 24px 24px rgba(110, 113, 145, 0.1), 0px 40px 48px rgba(110, 113, 145, 0.25)',
    dp28: '0px 28px 28px rgba(110, 113, 145, 0.15), 0px 48px 56px rgba(110, 113, 145, 0.25)',
    negative: {
      dp2: '0px -2px 2px rgba(110, 113, 145, 0.04), 0px -2px 6px rgba(110, 113, 145, 0.04)',
      dp4: '0px -4px 4px rgba(110, 113, 145, 0.06), 0px -4px 8px rgba(110, 113, 145, 0.06)',
      dp12: '0px -12px 12px rgba(110, 113, 145, 0.08), 0px -16px 24px rgba(110, 113, 145, 0.08)'
    }
  }
} as const
