import { t } from 'i18next'

import { RightArrow } from './commons'

const GenericContent = ({ prevValue, newValue }: DefaultChangeProps) => (
  <>
    <span className="prevValue">
      {['', null].includes(prevValue) ? (
        <i>{t('rm:campaign.logs.empty_value')}</i>
      ) : (
        String(prevValue)
      )}
    </span>
    <RightArrow />
    <span className="newValue">
      {['', null].includes(newValue) ? (
        <i>{t('rm:campaign.logs.empty_value')}</i>
      ) : (
        String(newValue)
      )}
    </span>
  </>
)

export default GenericContent
