import { useCallback, useMemo, useRef, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { Button } from '~/components/Buttons'
import { formatDateToString } from '~/helpers'
import { useSidePanel } from '~/hooks/useSidePanel'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { useAppSelector } from '~/store/hooks'

import { AddAd } from '../../AddAd'

const AddAdWrapper: React.FC = () => {
  const { t } = useAPPTranslation()
  const { id } = useParams()
  const { addSidePanel, removeSidePanel } = useSidePanel()
  const formRef = useRef(null)

  const {
    campaign: {
      item: { data }
    }
  } = useAppSelector(state => state.retailMedia)

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const queryParams = useMemo(
    () =>
      ({
        start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
        end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
        campaign_id: id
      }) as RequestCampaignGet,
    [startDate, endDate, id]
  )

  const canEdit = useMemo(() => data?.canEditCampaign, [data?.canEditCampaign])

  const isProductType = useMemo(
    () => data?.adType === 'product',
    [data?.adType]
  )

  const handleClickOnSave = useCallback(() => {
    if (formRef.current) {
      formRef.current.submitForm()
    }
  }, [])

  // Handle open panel
  const handleOpenPanel = useCallback(() => {
    addSidePanel({
      title: t('rm:ad.add'),
      type: 'content',
      size: 'large',
      content: (
        <AddAd
          adType={data.adType}
          showEdit
          formRef={formRef}
          queryParams={queryParams}
          handleClickOnSave={handleClickOnSave}
          handleClose={removeSidePanel}
        />
      )
    })
  }, [
    addSidePanel,
    data?.adType,
    handleClickOnSave,
    queryParams,
    removeSidePanel,
    t
  ])

  return (
    <>
      {isProductType && canEdit && (
        <Button
          iconLeft={<FiPlus />}
          text={t('rm:ad.add')}
          onClick={handleOpenPanel}
          size="small"
        />
      )}
    </>
  )
}

export default AddAdWrapper
