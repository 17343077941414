import { FiHelpCircle } from 'react-icons/fi'

import Tooltip from '~/components/Tooltip'

import EditBudgetColumn from './EditBudget'

type CostColumnProps = {
  isListAll: boolean
  campaignAdType?: CampaignAdType
}
type CostColumn = (data: CostColumnProps) => SchemaItems

export const cost: CostColumn = ({ isListAll, campaignAdType }) => {
  if (campaignAdType === 'sponsored_brand') return {}

  const label = {
    product: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>CPC</span>
        <Tooltip
          icon={<FiHelpCircle />}
          text="Custo por clique - valor máximo cobrado por cada clique em um anúncio."
        />
      </div>
    ),
    banner: <>CPM</>
  } as { [key in CampaignAdType]: JSX.Element }

  return {
    cost: {
      title: isListAll ? 'CPC/CPM' : label?.[campaignAdType] || 'Custo',
      render: EditBudgetColumn
    }
  }
}
